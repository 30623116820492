import { Button } from '@myosh/odin-components';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { Id, toast } from 'react-toastify';
import { DynamicFormSettings } from '../../@types/form-data-types';
import recordSelectors from '../../modules/diagram/diagramSelectors';
import DiagramService from '../../modules/diagram/diagramService';
import { useAppSelector } from '../../modules/hooks';
import { BowtieStateCauseData, BowtieStateConsequenceData, BowtieStateData } from '../../services/bowtie-data-types';
import { BowtieConfiguration } from '../../services/common-data-types';
import { RecordResult } from '../../services/record-data-types';
import { RecordService } from '../../services/record-service';

const diagramService = new DiagramService();
const recordService = new RecordService('test');

const SaveButton = () => {
  const [loading, setLoading] = useState(false);
  const bowtieData: BowtieStateData = useAppSelector(recordSelectors.selectBowtieData);

  const handleClick = async () => {
    setLoading(true);

    // notify user
    const toastId = toast.loading('Creating Risk Document...');

    // create diagram
    const riskScenarioRecordId = await saveAIGeneratedDiagram(bowtieData, toastId);

    // update notification message
    toast.update(toastId, {
      render: riskScenarioRecordId ? 'Diagram created successfully' : 'Unable to create diagram',
      type: riskScenarioRecordId ? 'info' : 'error',
      isLoading: false,
      autoClose: 2000,
    });

    setLoading(false);

    if (riskScenarioRecordId) {
      // Reload the page and load the newly created bowtie diagram
      const storedSchema = localStorage.getItem('schema') ?? '';
      const params = new URLSearchParams({ schemaName: storedSchema, records: String(riskScenarioRecordId) });
      setTimeout(() => window.location.replace(`${window.location.origin}?${params}`), 2500);
    }
  };

  return (
    <Button onClick={handleClick} type="primary" disabled={loading}>
      {loading ? 'Creating...' : 'Create Backend Records'}
    </Button>
  );
};

export default SaveButton;

// Save the AI generated diagram and returns the risk scenario recordId
const saveAIGeneratedDiagram = async (bowtieData: BowtieStateData, toastId: Id) => {
  const { bowtieConfiguration, causes, consequences, scenario, hazard } = bowtieData;

  try {
    if (!bowtieConfiguration) {
      throw new Error('Unable to create diagram, bowtie configuration data is missing.');
    }

    const {
      scenario: { captionField: scenarioCaptionField } = {},
      hazard: { captionField: hazardCaptionField } = {},
      forms: { main: { id: mainFormId, form: mainForm } = {} } = {},
    } = bowtieConfiguration;

    // Add the Risk Scenario record
    const riskScenarioRecordDefaultStatus =
      (mainForm as DynamicFormSettings).workflowSteps.find((step) => step.draft === true)?.label ?? 'Default';

    const riskScenarioResponse = await diagramService.addRiskScenario(
      mainForm!,
      scenarioCaptionField!,
      scenario,
      riskScenarioRecordDefaultStatus
    );

    if (!riskScenarioResponse.success) {
      throw new Error('Unable to save risk scenario');
    }

    const riskScenarioResponseSuccess = riskScenarioResponse.payload as AxiosResponse<{
      result: Array<RecordResult>;
    }>;

    const riskScenarioRecord = riskScenarioResponseSuccess.data.result[0];
    const riskScenarioRecordId = riskScenarioRecord.id!;

    // update the main notification message
    toast.update(toastId, { render: 'Creating Causes and Preventative Controls...' });

    // Add the Causes and the Preventative Controls records
    const preventativeControlRecordIdsPromises: Promise<Array<string>> = addCausesAndPreventativeControlsRecords(
      bowtieConfiguration,
      riskScenarioRecordId,
      causes
    );

    // display a second notification for the parallel process
    const subToastId = toast.loading('Creating Consequences and Mitigating Controls...');

    // Add the Consequences and the Mitigating Controls records
    const mitigatingControlRecordIdsPromises: Promise<Array<string>> = addConsequencesAndMitigatingControlsRecords(
      bowtieConfiguration,
      riskScenarioRecordId,
      consequences
    );

    const [preventativeControlRecordIdsPromisesResult, mitigatingControlRecordIdsPromisesResult] =
      await Promise.allSettled([preventativeControlRecordIdsPromises, mitigatingControlRecordIdsPromises]);

    // Update the risk scenario record with the Control Ids and Hazard
    let preventativeControlIds: Array<string> = [];
    if (
      preventativeControlRecordIdsPromisesResult.status === 'fulfilled' &&
      preventativeControlRecordIdsPromisesResult.value.length > 0
    ) {
      preventativeControlIds = preventativeControlRecordIdsPromisesResult.value;
    }

    let mitigatingControlIds: Array<string> = [];
    if (
      mitigatingControlRecordIdsPromisesResult.status === 'fulfilled' &&
      mitigatingControlRecordIdsPromisesResult.value.length > 0
    ) {
      mitigatingControlIds = mitigatingControlRecordIdsPromisesResult.value;
    }

    // update the notifications
    toast.dismiss(subToastId);
    toast.update(toastId, { render: 'Linking Document and Finalising Layout...' });

    await recordService.setControlIdsToRiskScenarioRecord(
      riskScenarioRecordId,
      riskScenarioRecord.status,
      mainFormId!,
      preventativeControlIds,
      mitigatingControlIds,
      hazardCaptionField!,
      hazard
    );

    return riskScenarioRecordId;
  } catch (error) {
    // The caller will notify the user that the creation failed
    console.error('error');
  }
};

const addCausesAndPreventativeControlsRecords = async (
  bowtieConfiguration: BowtieConfiguration,
  riskScenarioRecordId: number,
  causes?: BowtieStateCauseData[]
) => {
  const {
    forms: {
      causes: { id: causesFormId, moduleId: causesModuleId, form: causesForm } = {},
      controls: { id: controlsFormId, moduleId: controlsModuleId, form: controlsForm } = {},
    } = {},
    preventativeControls: {
      captionField: preventativeControlsCaptionField,
      causes: { captionField: preventativeControlsCausesCaptionField } = {},
    } = {},
  } = bowtieConfiguration;

  const preventativeControlRecordIds: Array<string> = [];

  if (causes && causes.length > 0) {
    const causeResponsePromises = causes.map((cause) => {
      // create the Cause record promise
      return recordService
        .addCauseConsequence(
          cause.value,
          causesFormId!,
          causesModuleId!,
          preventativeControlsCausesCaptionField!,
          causesForm!,
          riskScenarioRecordId
        )
        .then(async (causeResponse) => {
          if (causeResponse.success) {
            const causeResponseSuccess = causeResponse.payload as unknown as AxiosResponse<{
              result: Array<RecordResult>;
            }>;

            const causeRecordId = causeResponseSuccess.data.result[0].id!;

            // Add the Preventative Controls records
            if (cause.preventativeControls && cause.preventativeControls.length > 0) {
              const preventativeControlsRecordsPromises = cause.preventativeControls.map((preventativeControl) => {
                return recordService.addControl(
                  String(preventativeControl.id!),
                  preventativeControl.value,
                  controlsFormId!,
                  controlsModuleId!,
                  causeRecordId,
                  preventativeControlsCaptionField!,
                  controlsForm!,
                  true // preventative control
                );
              });

              const results = await Promise.allSettled(preventativeControlsRecordsPromises);

              results.forEach((preventativeControlRecordPromise) => {
                if (
                  preventativeControlRecordPromise.status === 'fulfilled' &&
                  preventativeControlRecordPromise.value.success
                ) {
                  const preventativeControlResponseSuccess = preventativeControlRecordPromise.value
                    .payload as unknown as AxiosResponse<{
                    result: Array<RecordResult>;
                  }>;

                  const preventativeControlRecordId = preventativeControlResponseSuccess.data.result[0].id!;

                  // the control Id is needed to update the risk scenario record
                  preventativeControlRecordIds.push(String(preventativeControlRecordId));
                }
              });
            }
          }
        });
    });

    await Promise.allSettled(causeResponsePromises);
  }

  return preventativeControlRecordIds;
};

const addConsequencesAndMitigatingControlsRecords = async (
  bowtieConfiguration: BowtieConfiguration,
  riskScenarioRecordId: number,
  consequences?: BowtieStateConsequenceData[]
) => {
  const {
    forms: {
      consequences: { id: consequencesFormId, moduleId: consequencesModuleId, form: consequencesForm } = {},
      controls: { id: controlsFormId, moduleId: controlsModuleId, form: controlsForm } = {},
    } = {},
    mitigatingControls: {
      captionField: mitigatingControlsCaptionField,
      consequences: { captionField: mitigatingControlsConsequencesCaptionField } = {},
    } = {},
  } = bowtieConfiguration;

  const mitigatingControlRecordIds: Array<string> = [];

  if (consequences && consequences.length > 0) {
    const consequenceResponsePromises = consequences.map((consequence) => {
      // create the Consequence record
      return recordService
        .addCauseConsequence(
          consequence.value,
          consequencesFormId!,
          consequencesModuleId!,
          mitigatingControlsConsequencesCaptionField!,
          consequencesForm!,
          riskScenarioRecordId
        )
        .then(async (consequenceResponse) => {
          if (consequenceResponse.success) {
            const consequenceResponseSuccess = consequenceResponse.payload as unknown as AxiosResponse<{
              result: Array<RecordResult>;
            }>;

            const consequenceRecordId = consequenceResponseSuccess.data.result[0].id!;

            // Add the Mitigating Controls records
            if (consequence.mitigatingControls && consequence.mitigatingControls.length > 0) {
              const mitigatingControlsRecordsPromises = consequence.mitigatingControls.map((mitigatingControl) => {
                return recordService.addControl(
                  String(mitigatingControl.id!),
                  mitigatingControl.value,
                  controlsFormId!,
                  controlsModuleId!,
                  consequenceRecordId,
                  mitigatingControlsCaptionField!,
                  controlsForm!,
                  false // mitigating control
                );
              });

              const results = await Promise.allSettled(mitigatingControlsRecordsPromises);
              results.forEach((mitigatingControlRecordPromise) => {
                if (
                  mitigatingControlRecordPromise.status === 'fulfilled' &&
                  mitigatingControlRecordPromise.value.success
                ) {
                  const mitigatingControlResponseSuccess = mitigatingControlRecordPromise.value
                    .payload as unknown as AxiosResponse<{
                    result: Array<RecordResult>;
                  }>;
                  const mitigatingControlRecordId = mitigatingControlResponseSuccess.data.result[0].id!;
                  // the control Id is needed to update the risk scenario record
                  mitigatingControlRecordIds.push(String(mitigatingControlRecordId));
                }
              });
            }
          }
        });
    });

    await Promise.allSettled(consequenceResponsePromises);
  }

  return mitigatingControlRecordIds;
};
