import {
  Button,
  DomElementAlignment,
  DomTargetPosition,
  OdinIcon,
  OdinIconType,
  OverlayPanel,
} from '@myosh/odin-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAIContext } from '../../context/ai.context';
import { cn } from '../../helpers/util';
import filterActions from '../../modules/filter/filterActions';
import filterSelectors from '../../modules/filter/filterSelectors';
import { useAppDispatch, useAppSelector } from '../../modules/hooks';
import userSelectors from '../../modules/user/userSelectors';
import { DIAGRAM_MODE } from '../../services/bowtie-data-types';
import AIAnalysisSuggestionButton from '../ai-analysis-suggestion/ai-analysis-suggestion-button';
import DropDown from '../drop-down.component';
import BowtieWizard, { BowtieWizardRef } from '../wizard/bowtie-wizard';
import SaveButton from '../wizard/save-button.component';
import AboutModal from './about-modal';
import ReloadButton from './reload-button';
import MyoshLogo from '../../assets/svg/myosh-logo.svg';

type HeaderProps = {
  handleClickAIAnalysisButton: (text: string) => void;
};

function Header({ handleClickAIAnalysisButton }: HeaderProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);

  const menuButtonRef = useRef(null);
  const wizardRef = useRef<BowtieWizardRef>(null);

  const dispatch = useAppDispatch();
  const diagramMode = useAppSelector(filterSelectors.selectDiagramMode);
  const userWithPermissions = useAppSelector(userSelectors.selectUser);

  const isAIGenerated = useAIContext();

  const defaultItems = [
    {
      name: 'Bowtie',
      action: () => toggleDiagramMode(DIAGRAM_MODE.BOWTIE),
      icon: 'Information',
      className: diagramMode === DIAGRAM_MODE.BOWTIE ? 'bt-bg-primary-5' : '',
    },
    {
      name: 'Butterfly',
      action: () => toggleDiagramMode(DIAGRAM_MODE.BUTTERFLY),
      icon: 'MenuFold',
      className: diagramMode === DIAGRAM_MODE.BUTTERFLY ? 'bt-bg-primary-5' : '',
    },
    {
      name: 'About',
      action: () => {
        setIsAboutModalOpen(true);
        setIsMenuOpen(false);
      },
    },
  ];

  const toggleDiagramMode = (selectedDiagramMode: string) => {
    setIsMenuOpen(false);
    dispatch(filterActions.toggleDiagramMode(selectedDiagramMode));
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleAddBowtie = useCallback(() => {
    window.open(window.location.origin);
  }, []);

  const handleBowtieWizardClick = useCallback(() => {
    return wizardRef.current?.open();
  }, []);

  const focusRiskScenarioContainer = useCallback(() => {
    const riskScenarioContainer = document.getElementById('risk-scenario');
    if (riskScenarioContainer) {
      riskScenarioContainer.focus();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      focusRiskScenarioContainer();
    });
  }, []);

  const handleGenerateBowtie = (term: string, hazard: string, materialUnwantedEvent: string) => {
    if (term && hazard && materialUnwantedEvent) {
      const storedSchema = localStorage.getItem('schema') ?? '';
      // open new page and load bowtie
      const params = new URLSearchParams({ schemaName: storedSchema, term, hazard, mue: materialUnwantedEvent });
      window.open(`${window.location.origin}?${params}`);
    } else {
      toast.error('Please make sure that you have specified a term and have selected a risk.');
    }
  };

  const canCreateBowtie =
    userWithPermissions &&
    userWithPermissions.permissions &&
    userWithPermissions.permissions.createRecord &&
    userWithPermissions.permissions.createRecord.main;

  return (
    <>
      <div
        className="bt-sticky bt-left-0 bt-top-0 bt-z-[1300] bt-flex bt-justify-between bt-bg-mono-1 bt-pb-0 bt-pl-16 bt-pr-16 bt-pt-2"
        id="header"
      >
        <div className="bt-z-[3000] bt-flex bt-flex-row bt-gap-3">
          <img src={MyoshLogo} alt="myosh-logo" className="bt-h-12" />
          {!isAIGenerated && <DropDown />}
          {!isAIGenerated && canCreateBowtie && (
            <>
              <Button onClick={handleAddBowtie} type="primary">
                Add Bowtie
              </Button>
              <Button onClick={handleBowtieWizardClick}>Bowtie AI Wizard</Button>
              <AIAnalysisSuggestionButton handleClickAIAnalysisButton={handleClickAIAnalysisButton} />
            </>
          )}
          {!isAIGenerated && <ReloadButton />}
          {isAIGenerated && (
            <div className="bt-flex bt-flex-row bt-gap-2">
              <SaveButton />
              {/* NOTE: This button does nothing for now */}
              <Button type="text">Save</Button>
            </div>
          )}
        </div>
        <div className="bt-flex">
          <div className="bt-flex bt-pr-3">
            <div className="bt-flex bt-gap-6">
              <div className="bt-flex bt-items-center bt-gap-2">
                <div
                  id="critical"
                  className="bt-h-[1.2rem] bt-w-[1.2rem] bt-cursor-auto bt-text-wrap bt-rounded-[0.3rem] bt-bg-control-default"
                />
                <label>Control</label>
              </div>
              <div className="bt-flex bt-items-center bt-gap-2">
                <div
                  id="critical"
                  className="bt-h-[1.2rem] bt-w-[1.2rem] bt-cursor-auto bt-rounded-[0.3rem] bt-bg-control-critical"
                />
                <label>Critical Control</label>
              </div>
              <div className="bt-flex bt-items-center bt-gap-2">
                <div
                  id="non-critical"
                  className="bt-h-[1.2rem] bt-w-[1.2rem] bt-cursor-auto bt-rounded-[0.3rem] bt-bg-control-issue"
                />
                <label>Control Issues</label>
              </div>
            </div>
          </div>
          <div title="Switch layout" className="bt-flex bt-items-center">
            <div ref={menuButtonRef} onClick={toggleMenu} className="bt-text-primary-2 hover:bt-cursor-pointer">
              <OdinIcon icon="Menu" type={OdinIconType.Line} />
            </div>
          </div>
        </div>
      </div>
      <OverlayPanel
        visible={isMenuOpen}
        target={menuButtonRef.current}
        targetPosition={DomTargetPosition.BottomRight}
        elementAlignment={DomElementAlignment.TopRight}
        hidden={() => setIsMenuOpen(false)}
        shouldCheckZIndex
      >
        <div className="bt-mx-auto bt-my-0 bt-min-w-[120px] bt-max-w-fit bt-rounded-lg bt-bg-mono-1 bt-text-left bt-shadow-sm">
          {defaultItems.map((item) => {
            return (
              <div
                key={item.name}
                className={cn(
                  'bt-px-3 bt-py-2 bt-text-base hover:bt-cursor-pointer hover:bt-bg-primary-2 hover:bt-text-mono-1',
                  item.className
                )}
                onClick={item.action}
              >
                {item.name}
              </div>
            );
          })}
        </div>
      </OverlayPanel>
      <AboutModal isOpen={isAboutModalOpen} onClose={() => setIsAboutModalOpen(false)} />
      <BowtieWizard ref={wizardRef} onGenerateBowtie={handleGenerateBowtie} />
    </>
  );
}

export default Header;
